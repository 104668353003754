export var types;

(function (types) {
  types["SmallCaps"] = "small-caps";
  types["XtraSmall"] = "xs";
  types["Small"] = "s";
  types["Medium"] = "m";
  types["Large"] = "l";
  types["ExtraLarge"] = "xl";
})(types || (types = {}));

export var boldTypes;

(function (boldTypes) {
  boldTypes["light"] = "fwLight";
  boldTypes["regular"] = "fwRegular";
  boldTypes["medium"] = "fwMedium";
  boldTypes["bold"] = "fwBold";
  boldTypes["black"] = "fwBlack";
})(boldTypes || (boldTypes = {}));