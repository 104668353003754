export var Rank;

(function (Rank) {
  Rank["First"] = "1";
  Rank["Second"] = "2";
  Rank["Third"] = "3";
  Rank["Forth"] = "4";
  Rank["Fith"] = "5";
  Rank["Sixth"] = "6";
})(Rank || (Rank = {}));

export var Sizes;

(function (Sizes) {
  Sizes["SMALLCAPS"] = "small-caps";
  Sizes["XXXTRASMALL"] = "xxxs";
  Sizes["XXTRASMALL"] = "xxs";
  Sizes["XTRASMALL"] = "xs";
  Sizes["SMALL"] = "s";
  Sizes["MEDIUM"] = "m";
  Sizes["LARGE"] = "l";
  Sizes["EXTRALARGE"] = "xl";
  Sizes["XEXTRALARGE"] = "xxl";
  Sizes["XXEXTRALARGE"] = "xxxl";
})(Sizes || (Sizes = {}));

export var HttpStatusCodes;

(function (HttpStatusCodes) {
  HttpStatusCodes[HttpStatusCodes["Ok"] = 200] = "Ok";
  HttpStatusCodes[HttpStatusCodes["NoContent"] = 204] = "NoContent";
  HttpStatusCodes[HttpStatusCodes["BadRequest"] = 400] = "BadRequest";
  HttpStatusCodes[HttpStatusCodes["NotFound"] = 404] = "NotFound";
  HttpStatusCodes[HttpStatusCodes["Conflict"] = 409] = "Conflict";
  HttpStatusCodes[HttpStatusCodes["Error"] = 500] = "Error";
})(HttpStatusCodes || (HttpStatusCodes = {}));

export var VerticalSpacingSizes;

(function (VerticalSpacingSizes) {
  VerticalSpacingSizes["XS"] = "xs";
  VerticalSpacingSizes["S"] = "s";
  VerticalSpacingSizes["M"] = "m";
  VerticalSpacingSizes["L"] = "l";
  VerticalSpacingSizes["XL"] = "xl";
})(VerticalSpacingSizes || (VerticalSpacingSizes = {}));

export var ElementStates;

(function (ElementStates) {
  ElementStates["SELECTED"] = "selected";
})(ElementStates || (ElementStates = {}));